import store from '@/store'
const title = store.getters.title

export default {
  targetArr: [
    {
      title: '用户行为指标',
      list: [
        {
          label: 'DAU',
          width: 120,
          prop: 'placeDau',
          align: 'left',
          desc: `${title}统计到的请求该广告位的独立用户数`,
          sorter: true
        },
        {
          label: 'DEU',
          width: 120,
          prop: 'deu',
          align: 'left',
          desc: '每天观看过广告的独立用户数',
          sorter: true
        },
        {
          label: '渗透率',
          width: 120,
          prop: 'permeability',
          align: 'left',
          desc: '每天观看过广告的用户占比，渗透率=DEU/DAU',
          sorter: true
        },
        {
          label: '展示/DAU',
          width: 150,
          prop: 'aipau',
          align: 'left',
          desc: '人均展示次数',
          sorter: true
        },
        {
          label: '展示/DEU',
          width: 150,
          prop: 'aipu',
          align: 'left',
          desc: '观看过广告的用户人均展示次数',
          sorter: true
        },
        {
          label: '人均收益',
          width: 150,
          prop: 'estimateArpu',
          align: 'left',
          desc: '--',
          sorter: true
        }
      ]
    },
    {
      title: '数据指标',
      list: [
        {
          label: '预估收益',
          width: 150,
          prop: 'income',
          align: 'left',
          desc: `预估收益=SUM(广告源人工填写的eCPM价格*${title}统计的展示/1000)`,
          sorter: true
        },
        {
          label: '预估eCPM',
          width: 150,
          prop: 'estimatedRevenueEcpm',
          align: 'left',
          desc: `（预估收益/${title}统计的展示）*1000`,
          sorter: true
        },
        {
          label: '流量请求',
          width: 150,
          prop: 'request',
          align: 'left',
          desc: `应用向${title}发送请求的次数，一次流量请求可能触发多次广告请求`,
          sorter: true
        },
        {
          label: '流量填充率',
          width: 150,
          prop: 'requestFilledRate',
          align: 'left',
          desc: `应用向${title}发送请求后，响应成功的占比`,
          sorter: true
        },
        {
          label: '展示',
          width: 150,
          prop: 'impress',
          align: 'left',
          desc: `${title}统计的广告曝光次数，由于统计口径差异、网络丢包等因素，${title}统计的展示数据与广告平台展示数据可能存在差异`,
          sorter: true
        },
        {
          label: '展示率',
          width: 120,
          prop: 'impressRate',
          align: 'left',
          desc: `${title}收到来自广告平台的广告返回后，展示成功的占比。公式为 展示率 = (展示/填充)`,
          sorter: true
        },
        {
          label: '点击',
          width: 120,
          prop: 'click',
          align: 'left',
          desc: `${title}统计的广告点击数，由于部分广告平台不提供点击回调，${title}统计的点击数据与广告平台点击数据可能存在差异`,
          sorter: true
        },
        {
          label: '点击率',
          width: 120,
          prop: 'clickRate',
          align: 'left',
          desc: `${title}统计的点击率，点击率=点击数/展示数`,
          sorter: true
        },
        {
          label: '展请率',
          width: 100,
          prop: 'impressRequestRate',
          align: 'left',
          desc: `${title}统计到的展示占${title}收到的请求比例；`,
          sortable: false
        }
      ]
    }
  ],
  sureArr: [
    {
      label: '渗透率',
      width: 120,
      prop: 'permeability',
      align: 'left',
      desc: '每天观看过广告的用户占比，渗透率=DEU/DAU',
      sorter: true
    },
    {
      label: '预估收益',
      width: 150,
      prop: 'income',
      align: 'left',
      desc: `预估收益=SUM(广告源人工填写的eCPM价格*${title}统计的展示/1000)`,
      sorter: true
    },
    {
      label: '预估eCPM',
      width: 150,
      prop: 'estimatedRevenueEcpm',
      align: 'left',
      desc: `（预估收益/${title}统计的展示）*1000`,
      sorter: true
    },
    {
      label: '流量填充率',
      width: 150,
      prop: 'requestFilledRate',
      align: 'left',
      desc: `应用向${title}发送请求后，响应成功的占比`,
      sorter: true
    },
    {
      label: '展示率',
      width: 120,
      prop: 'impressRate',
      align: 'left',
      desc: `${title}收到来自广告平台的广告返回后，展示成功的占比。公式为 展示率 = (展示/填充)`,
      sorter: true
    },
    {
      label: '点击',
      width: 120,
      prop: 'click',
      align: 'left',
      desc: `${title}统计的广告点击数，由于部分广告平台不提供点击回调，${title}统计的点击数据与广告平台点击数据可能存在差异`,
      sorter: true
    },
    {
      label: '点击率',
      width: 120,
      prop: 'clickRate',
      align: 'left',
      desc: `${title}统计的点击率，点击率=点击数/展示数`,
      sorter: true
    }
  ]
}
