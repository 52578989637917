<template>
  <a-modal
    v-model="addPlaceVisible"
    :title="(isAdd ? '添加' : '修改') + '广告位'"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    dialogClass="alliance_addplace_modal"
  >
    <div class="add_place_content">
      <a-form-model ref="ruleForm" :model="query" :rules="rules" v-bind="layout">
        <a-form-model-item label="媒体联盟" prop="appId">
          <a-select :disabled="!isAdd" v-model="query.appId" placeholder="请选择媒体联盟">
            <a-select-option v-for="item in appList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="广告位名称" prop="name">
          <a-input v-model.trim="query.name" autocomplete="off" placeholder="请输入广告位名称" />
        </a-form-model-item>
        <a-form-model-item label="广告样式" prop="position">
          <a-select v-model="query.position" placeholder="请选择广告样式" @change="changePosition">
            <a-select-option v-for="item in positionList" :key="item.id" :value="item.id">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="素材模板" prop="templateIds">
          <a-select v-model="query.templateIds" mode="multiple" :maxTagCount="1" placeholder="请选择素材模板">
            <a-select-option v-for="item in templateList" :key="item.id" :value="item.id">{{
              item.sizeName
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <!-- <a-form-model-item label="审核状态" prop="auditSts">
        <a-switch size="small" default-checked :checked="query.auditSts === 'A'" @change="handleChangeSts"> </a-switch>
      </a-form-model-item> -->
        <a-form-model-item label="分成比例" prop="divideIntoProportions">
          <a-input v-model.trim="query.divideIntoProportions" autocomplete="off" placeholder="请输入分成比例（0-100）" />
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark">
          <a-input v-model.trim="query.remark" autocomplete="off" placeholder="请输入备注" />
        </a-form-model-item>
        <a-form-model-item v-if="query.position === 5" label="服务端激励回调" prop="serverVerify">
          <a-switch :checked="query.serverVerify === 1" @change="handleServerVerify" />
        </a-form-model-item>
        <a-form-model-item v-if="query.serverVerify === 1 && query.position === 5" label="激励名称" prop="rewardName">
          <a-input v-model.trim="query.rewardName" autocomplete="off" placeholder="请输入激励名称" />
        </a-form-model-item>
        <a-form-model-item v-if="query.serverVerify === 1 && query.position === 5" label="激励数量" prop="rewardCount">
          <a-input-number
            style="width: 200px"
            v-model.trim="query.rewardCount"
            autocomplete="off"
            placeholder="请输入激励数量"
          />
        </a-form-model-item>
        <a-form-model-item v-if="query.serverVerify === 1 && query.position === 5" label="回调URL" prop="callbackUrl">
          <a-input v-model.trim="query.callbackUrl" autocomplete="off" placeholder="请输入回调URL" />
        </a-form-model-item>
        <a-form-model-item v-if="query.serverVerify === 1 && query.position === 5" label="安全密钥" prop="secKey">
          <a-input :disabled="true" v-model.trim="query.secKey" autocomplete="off" placeholder="请输入安全密钥" />
        </a-form-model-item>
        <a-form-model-item v-if="query.position === 1 && skip === 1" label="是否跳过" prop="skip">
          <a-radio-group v-model="query.skip">
            <a-radio value="A"> 开启 </a-radio>
            <a-radio value="S"> 关闭 </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import { getAllAppPage, getMaterialTemplateList, placeUpdate, addPlace } from '@/api/media'
import { mapState } from 'vuex'
import { getPositionList } from '@/api/common'
import md5 from 'js-md5'
export default {
  data () {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      query: {},
      rules: {
        appId: [{ required: true, message: '请选择媒体联盟', trigger: 'change' }],
        name: [{ required: true, message: '请输入广告位名称', trigger: 'blur' }],
        position: [{ required: true, message: '请选择广告样式', trigger: 'change' }]
      },
      appList: [],
      positionList: [],
      templateList: []
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    appId: {
      default: '',
      type: String
    },
    placeInfo: {
      default: () => ({
        appId: '',
        appName: '',
        auditSts: 'S',
        cacheTime: 1,
        createTime: '',
        divideIntoProportions: 100,
        id: '',
        name: '',
        os: 1,
        platNum: 1,
        position: undefined,
        remark: '',
        skip: 'S',
        sts: 'S',
        templateIds: [],
        rewardName: undefined,
        rewardCount: 0,
        callbackUrl: undefined,
        serverVerify: 0,
        level: 1
      }),
      type: Object
    }
  },
  watch: {
    placeInfo: {
      handler (val) {
        this.query = JSON.parse(JSON.stringify(val))
      },
      deep: true,
      immediate: true
    },
    appId: {
      handler (val) {
        if (val) {
          this.query.appId = val
        }
      },
      immediate: true
    }
  },
  mounted () {
    this.getAllAppPage()
    this.getPositionList()
    this.getMaterialTemplateList()
  },
  computed: {
    ...mapState({
      skip: (state) => state.user.skip
    }),
    addPlaceVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    changePosition (val) {
      if (this.isAdd) {
        const md = md5('' + new Date().getTime(), this.query.appId ? this.query.appId : '')
        this.query.secKey = md
      }
    },
    handleServerVerify (e) {
      this.query.serverVerify = e ? 1 : 0
      if (!this.isAdd) {
        const md = md5('' + new Date().getTime(), this.query.appId ? this.query.appId : '')
        this.query.secKey = md
      }
    },
    // handleChangeSts (sts) {
    //   this.query.auditSts = sts ? 'A' : 'S'
    // },
    async getMaterialTemplateList () {
      const resp = await getMaterialTemplateList()
      this.templateList = resp.data || []
    },
    async getPositionList () {
      const resp = await getPositionList()
      this.positionList = resp.data || []
    },
    async getAllAppPage () {
      const resp = await getAllAppPage()
      this.appList = resp.data[1].filter((item) => item.accessType === 1) || []
    },
    handleCancel () {
      this.addPlaceVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.isAdd) {
            const resp = await addPlace(this.query)
            if (resp.code === 200) {
              this.$message.success('添加成功!')
              this.$emit('changePlace')
              this.addPlaceVisible = false
            }
          } else {
            const resp = await placeUpdate(this.query)
            if (resp.code === 200) {
              this.$message.success('修改成功!')
              this.$emit('changePlace')
              this.addPlaceVisible = false
            }
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less">
.alliance_addplace_modal .ant-modal-body {
  background-color: #eee;
  .add_place_content {
    height: 500px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    overflow-y: auto;
  }
}
</style>
