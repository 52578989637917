<template>
  <a-modal
    v-model="addMediaVisible"
    title="资金充值"
    @onCancel="handleCancel"
    dialogClass="alliance_addapp_modal"
    :width="1000"
  >
    <!-- 资金充值 -->
    <div class="content">
      <a-form-model ref="ruleForm" :model="query" :rules="rules" v-bind="layout">
        <div class="title">
          <p>流程说明:</p>
          <span class="line"></span>
          <p><a-icon style="fontsize: 17px" type="profile" /> 打款到指定账户</p>
          <span class="dashed"></span>
          <p><a-icon style="fontsize: 17px" type="file-search" /> 提交充值申请</p>
          <span class="dashed"></span>
          <p><a-icon style="fontsize: 17px" type="file-done" /> 充值成功</p>
        </div>
        <!-- 充值金额 -->
        <a-form-model-item
          class="content_item"
          prop="incomeAmount"
          :rules="[
            {
              required: true,
              trigger: 'blur',
              message: '请输入充值金额'
            },
            {
              required: true,
              trigger: 'blur',
              validator: validatePass
            }
          ]"
          label="充值金额"
        >
          <a-input
            v-model.number.trim="query.incomeAmount"
            type="number"
            placeholder="充值金额不能低于1000元"
            style="width: 600px"
          />
          <a-popover placement="bottom" trigger="hover" overlayClassName="media_popover">
            <template #content>
              <span>请将充值款打入指定银行账户</span>
              <div class="copyCon">
                户名：北京前呈无限科技有限公司
                <a-tooltip placement="bottom">
                  <template slot="title">
                    <span>复制户名</span>
                  </template>
                  <CopySvg @click="copyId('北京前呈无限科技有限公司')" class="copyIcon"/>
                </a-tooltip>
              </div>
              <div class="copyCon">
                开户行：招商银行股份有限公司北京分行朝阳门支行
                <a-tooltip placement="bottom">
                  <template slot="title">
                    <span>复制开户行</span>
                  </template>
                  <CopySvg @click="copyId('招商银行股份有限公司北京分行朝阳门支行')" class="copyIcon"/>
                </a-tooltip>
              </div>
              <div class="copyCon">
                账号：110942422210401
                <a-tooltip placement="bottom">
                  <template slot="title">
                    <span>复制账号</span>
                  </template>
                  <CopySvg @click="copyId('110942422210401')" class="copyIcon"/>
                </a-tooltip>
              </div>
              <p>注意：充值金额不能低于一千元!</p>
            </template>
            <a-button type="primary">收款方账号信息</a-button>
          </a-popover>
        </a-form-model-item>
        <!-- 打款凭证 -->
        <a-form-model-item class="content_item" prop="fileUrl" label="打款凭证">
          <a-upload
            list-type="picture-card"
            :show-upload-list="false"
            :action="uploadUrl"
            :before-upload="beforeUpload"
            @change="fileUpload"
            :headers="header"
          >
            <img
              :key="query.fileUrl"
              v-if="query.fileUrl"
              :src="resourceUrl + query.fileUrl"
              style="width: 120px; height: 100%"
            />
            <div v-else>
              <a-icon style="fontsize: 30px" :type="iconloading ? 'loading' : 'cloud-upload'" />
            </div>
          </a-upload>
          <span class="picture_card">请上传清晰的银行转账凭证，支持PNG/JPG/JPEG，小于300kb</span>
          <span v-if="!haveImg" class="warning">请上传打款凭证</span>
        </a-form-model-item>
        <a-button type="primary" class="requestButton" @click="handleSubmit">申请充值</a-button>
      </a-form-model>
    </div>
    <!-- 财务信息 -->
    <div class="financial">
      <h3>财务信息</h3>
      <div class="financial_box">
        <div class="financial_item">
          <p>账号余额</p>
          <span> {{ industry.zijinRemainAmount || '--' }} </span>
        </div>
        <div class="financial_item">
          <p>公司名称</p>
          <span> {{ industry.accountName || '--' }} </span>
        </div>
        <div class="financial_item">
          <p>纳税人识别号</p>
          <span> {{ industry.taxNumber || '--' }} </span>
        </div>
        <div class="financial_item">
          <p>银行开户行</p>
          <span> {{ industry.accountBank || '--' }} </span>
        </div>
        <div class="financial_item">
          <p>银行账号</p>
          <span> {{ industry.accountCode || '--' }} </span>
        </div>
      </div>
      <div class="h5">注意：每次提交充值记录时，请确保充值金额与实际发生的打款完全一致。</div>
    </div>
    <template slot="footer">
      <a-button @click="handleCancel">关闭</a-button>
    </template>
  </a-modal>
</template>

<script>
import { taxInfoList, incomePay } from '@/api/media'
import { mapState } from 'vuex'
import { copy } from '@/utils/string'
import CopySvg from '@/assets/icons/copy.svg?inline'
export default {
  components: { CopySvg },
  data () {
    return {
      layout: {
        labelCol: { span: 3 },
        wrapperCol: { span: 18 }
      },
      form: this.$form.createForm(this),
      query: {},
      rules: {
        fileUrl: [{ required: true, message: '', trigger: 'blur' }]
      },
      industry: {},
      pid: [],
      currentApp: [],
      searchKey: '',
      iconUrl: '',
      myEcharts: null,
      spinning: false,
      uploadUrl: process.env.VUE_APP_API_BASE_URL + '/income/upload',
      haveImg: true,
      iconloading: false
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  watch: {},
  created () {},
  mounted () {
    // 获取发票信息
    this.getTaxInfoList()
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token,
      resourceUrl: (state) => state.autoweb.resourceUrl
    }),
    header () {
      return {
        Authorization: this.token
      }
    },
    addMediaVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    // 复制
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    // 图片上传
    fileUpload (info) {
      if (info.file.status === 'uploading') {
        this.iconloading = true
        this.haveImg = false
        return
      }
      if (info.file.status === 'done') {
        this.query.fileUrl = info.file.response.data
        this.iconloading = false
        this.haveImg = true
      }
    },
    // 上传
    beforeUpload (file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
      if (!isJpgOrPng) {
        this.$message.error('请选择上传 JPG JPEG PNG 格式的图片!')
      }
      const isLt300 = file.size / 1024 < 301
      if (!isLt300) {
        this.$message.error('文件大小不能超过300kb!')
      }
      return isJpgOrPng && isLt300
    },
    handleCancel () {
      this.addMediaVisible = false
    },
    // 获取发票信息
    async getTaxInfoList () {
      const res = await taxInfoList()
      if (res.code === 200) {
        this.industry = res?.data[0] || {}
      }
    },
    // 充值金额校验
    validatePass (rule, value, callback) {
      if (value < 1000) {
        callback(new Error('充值金额不能低于1000元！'))
      }
      callback()
    },
    // 申请充值
    handleSubmit () {
      this.haveImg = this.query.fileUrl
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid && this.haveImg) {
          const resp = await incomePay(this.query)
          if (resp.code === 200) {
            this.$message.success('申请充值成功!')
            this.$emit('changeAppInfo', 'chongzhi')
            this.addMediaVisible = false
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep {
  .ant-modal-wrap .ant-modal .ant-modal-body {
    padding: 10px;
  }
  .ant-empty {
    margin: 0;
    margin-right: 8px;
    border-radius: 5px;
  }
  .ant-popover-inner-content {
    border-radius: 8px;
    background: #f5f4f4;
  }
  .ant-popover-arrow {
    background: #f5f4f4;
  }
  .alliance_addapp_modal {
    .content {
      height: 450px;
      background-color: #fff;
      border-radius: 5px;
      overflow-y: auto;
      display: flex;
      justify-content: center;
      padding: 40px 0;
      .title {
        width: 800px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 20px;
        background: #f2f2f2;
        border-radius: 22px;
        margin-bottom: 30px;
        font-family: PingFangSC-Regular, PingFang SC;
        .line {
          width: 1px;
          height: 24px;
          border: 1px solid #e7e7e7;
          margin: 0 20px;
        }
        .dashed {
          height: 1px;
          width: 70px;
          border: 0.5px #9b9898 dashed;
          margin: 0 20px;
        }
        p {
          margin: 0;
          font-size: 14px;
          color: #020202;
        }
      }
      .content_item {
        display: flex;
        p {
          width: 60px;
          text-align: right;
          margin-right: 30px;
        }
        img {
          width: 30px;
          height: 30px;
          border-radius: 5px;
          margin-right: 9px;
        }
        .ant-upload.ant-upload-select-picture-card {
          border-radius: 10px;
          border: 1px solid #ebebef;
          background: #fbfbfd;
          margin-bottom: 20px;
        }
        .picture_card {
          border-radius: 5px;
          padding: 16px 20px;
          background: #f2f2f2;
          color: #a6a6a6;
          font-size: 12px;
        }
        .warning {
          position: absolute;
          left: 0;
          top: -10px;
          color: #f5222d;
        }
        .ant-input-group.ant-input-group-compact .ant-input {
          border-radius: 6px;
          position: relative;
        }
        .ant-btn.ant-btn-primary {
          border-radius: 10px;
          position: absolute;
          height: 38px;
          top: -10px;
          right: -5px;
        }
      }
      .requestButton {
        border-radius: 10px;
        margin-left: 15%;
      }
      .img_box {
        display: flex;
        flex-flow: row wrap;
        .a_image {
          width: 190px;
          height: 130px;
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }
    }
    .financial {
      margin-top: 10px;
      background-color: #fff;
      border-radius: 5px;
      padding: 30px 10%;
      h2 {
        margin-bottom: 20px;
      }
      .financial_box {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 20px 0;
        .financial_item {
          width: 30%;
          font-size: 14px;
          font-weight: 500;
          color: #4d4d4d;
          font-size: 14px;
          display: flex;
          margin-bottom: 20px;
          margin-right: 30px;
          p {
            width: 120px;
            margin-right: 20px;
          }
          span {
            width: 75%;
          }
        }
        .financial_item:nth-child(3) {
          margin-right: 0;
        }
      }
      .h5 {
        color: #e02020;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
</style>
<style lang="less">
.media_popover {
  width: 280px;
  .ant-popover-inner {
    border-radius: 8px;
  }
  .ant-popover-inner-content {
    background: #f5f4f4;
    border-radius: 8px;
    padding: 20px 20px 20px 30px;

    span {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
    }
    .copyCon {
      margin-top: 10px;
      font-size: 12px;
      font-weight: 500;
      color: #333333;
      .copyIcon {
        cursor: pointer;
        color: #5c8aff;
        margin-left: 5px;
        font-size: 14px;
      }
    }
    p {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #e02020;
      margin-top: 25px;
    }
  }
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  border-right-color: #f5f4f4;
  border-bottom-color: #f5f4f4;
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  border-top-color: #f5f4f4;
  border-left-color: #f5f4f4;
}
</style>
