var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"批量添加广告位","width":1200},on:{"onCancel":_vm.handleCancel,"ok":_vm.handleSubmit},model:{value:(_vm.batchAddPlaceVisible),callback:function ($$v) {_vm.batchAddPlaceVisible=$$v},expression:"batchAddPlaceVisible"}},[_c('div',{staticClass:"batch-add-place-container"},[_c('a-button',{staticStyle:{"margin":"10px"},attrs:{"type":"primary"},on:{"click":_vm.addPlaceList}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v("添加广告位")],1),_c('a-form-model',_vm._b({ref:"ruleForm",attrs:{"model":_vm.query,"rules":_vm.rules}},'a-form-model',_vm.layout,false),[_c('a-table',{attrs:{"size":"middle","rowKey":(record, index) => index,"data-source":_vm.query.placeList,"pagination":false,"scroll":{ x: true },"bordered":""}},[_c('a-table-column',{attrs:{"align":"center","title":"操作","width":"150px","fixed":true},scopedSlots:_vm._u([{key:"default",fn:function(text, record, index){return [_c('div',[_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.handleCopyItem(record)}}},[_vm._v("复制")]),_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.handleDeleteItem(index)}}},[_vm._v("删除")])],1)]}}])}),_c('a-table-column',{attrs:{"align":"left","title":"应用"},scopedSlots:_vm._u([{key:"default",fn:function(text, record, index){return [_c('a-form-model-item',{staticClass:"form_item",attrs:{"prop":`placeList[${index}].appId`,"rules":[
                {
                  required: true,
                  message: '请选择应用',
                  trigger: 'change'
                }
              ]}},[_c('m-select-one',{staticClass:"col_item",staticStyle:{"width":"220px"},attrs:{"allowClear":"","label":"应用","searchById":true,"hasOs":"","hasPosition":false,"allData":_vm.appList},on:{"change":function($event){return _vm.changeAppIdOrPosition(record.appId, index, record)}},model:{value:(record.appId),callback:function ($$v) {_vm.$set(record, "appId", $$v)},expression:"record.appId"}})],1)]}}])}),_c('a-table-column',{attrs:{"align":"left","title":"广告样式"},scopedSlots:_vm._u([{key:"default",fn:function(text, record, index){return [_c('a-form-model-item',{staticClass:"form_item",attrs:{"prop":`placeList[${index}].position`,"rules":[
                {
                  required: true,
                  message: '请选择广告样式',
                  trigger: 'change'
                }
              ]}},[_c('a-select',{staticStyle:{"width":"160px"},attrs:{"placeholder":"请选择广告样式"},on:{"change":function($event){return _vm.changeAppIdOrPosition(record.position, index, record)}},model:{value:(record.position),callback:function ($$v) {_vm.$set(record, "position", $$v)},expression:"record.position"}},_vm._l((_vm.positionList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1)]}}])}),_c('a-table-column',{staticClass:"table_item",attrs:{"align":"left","title":"广告位名称"},scopedSlots:_vm._u([{key:"default",fn:function(text, record, index){return [_c('a-form-model-item',{attrs:{"prop":`placeList[${index}].name`,"rules":[
                {
                  required: true,
                  message: '请输入广告位名称',
                  trigger: 'blur'
                }
              ]}},[_c('a-input',{staticStyle:{"width":"180px"},attrs:{"placeholder":"请输入广告位名称"},on:{"input":function($event){return _vm.handleChangeName($event, index, 'name', record)}},model:{value:(record.name),callback:function ($$v) {_vm.$set(record, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"record.name"}})],1)]}}])}),_c('a-table-column',{attrs:{"align":"left","title":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(text, record, index){return [_c('a-form-model-item',{staticClass:"form_item",attrs:{"prop":`placeList[${index}].sts`}},[_c('a-switch',{attrs:{"checked":record.sts === 'A'},on:{"change":function($event){return _vm.changeSts($event, index)}}})],1)]}}])})],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }