<template>
  <a-modal
    v-model="addAppVisible"
    :title="(isAdd ? '添加' : '修改') + '应用'"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    dialogClass="addapp_modal"
    :width="700"
  >
    <template slot="footer">
      <a-button key="back" @click="handleCancel"> 取消 </a-button>
      <a-button key="submit" type="primary" @click="handleSubmit"> 确定 </a-button>
    </template>
    <div class="add_app_content">
      <a-form-model ref="ruleForm" :model="query" :rules="rules" v-bind="layout">
        <a-form-model-item label="系统平台" prop="os">
          <a-radio-group v-model="query.os">
            <a-radio :value="1"> Android </a-radio>
            <a-radio :value="0"> IOS </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="应用名称" prop="name">
          <a-input v-model.trim="query.name" autocomplete="off" placeholder="请输入应用名称" />
        </a-form-model-item>
        <a-form-model-item label="应用包名" prop="bundle">
          <a-input v-model.trim="query.bundle" autocomplete="off" placeholder="请输入应用包名" />
        </a-form-model-item>
        <!-- <a-form-model-item label="媒体账户" prop="mediaUser" v-if="isAdmin !== '2'">
          <m-select-one
            v-if="showMediaList"
            class="search_item"
            v-model="query.mediaUser"
            :allData="mediaAccountList"
            :searchById="true"
            :hasIcon="true"
            style="width: 265px"
            :showId="false"
            width="450px"
            label="媒体账号"
          />
        </a-form-model-item>
        <a-form-model-item label="下载地址" prop="downloadUrl">
          <a-input v-model.trim="query.downloadUrl" autocomplete="off" placeholder="请输入下载地址" />
        </a-form-model-item> -->
        <a-form-model-item label="应用行业" prop="tradeId">
          <a-cascader
            :fieldNames="fieldNames"
            v-model="pid"
            :options="industry"
            placeholder="请选择应用行业"
            style="width: 270px"
            @change="changeTradeId"
            :allowClear="false"
          />
        </a-form-model-item>
        <!-- accessType 接入类型-->
        <a-form-model-item prop="accessType">
          <!-- <a-select style="width: 120px" v-model="query.accessType">
            <a-select-option :value="0"> SDK </a-select-option>
            <a-select-option :value="1"> API </a-select-option>
          </a-select> -->
          <m-tips slot="label" :content="`您的APP集成了${title} SDK进行变现`" :title="'接入类型'" :width="200"></m-tips>
          <a-radio-group v-model="query.accessType">
            <a-radio :value="0"> SDK </a-radio>
            <a-radio :value="1"> API </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="状态" prop="sts">
          <a-radio-group v-model="query.sts">
            <a-radio value="A"> 开启 </a-radio>
            <a-radio value="S"> 关闭 </a-radio>
          </a-radio-group>
          <!-- <a-select default-value="S" style="width: 120px" v-model="query.sts">
            <a-select-option value="A"> 开启 </a-select-option>
            <a-select-option value="S"> 关闭 </a-select-option>
          </a-select> -->
        </a-form-model-item>
        <a-form-model-item label="图标" prop="iconUrl">
          <a-upload
            list-type="picture-card"
            :show-upload-list="false"
            :action="uploadUrl"
            :before-upload="beforeUpload"
            @change="handleChange"
            :headers="header"
          >
            <img
              :key="query.iconUrl"
              v-if="query.iconUrl"
              :src="resourceUrl + query.iconUrl"
              alt="图标"
              style="width: 120px"
            />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import { industrySelect, appUpdate, appAdd } from '@/api/media'
import {
  mediaAccountList // 媒体账户下拉框
} from '@/api/reportdatas'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      form: this.$form.createForm(this),
      query: {
        os: 0,
        accessType: 0,
        sts: 'A'
      },
      rules: {
        os: [{ required: true, message: '请选择系统平台', trigger: 'change' }],
        name: [{ required: true, message: '请输入应用名称', trigger: 'blur' }],
        bundle: [{ required: true, message: '请输入应用包名', trigger: 'blur' }]
      },
      industry: [],
      fieldNames: { label: 'name', value: 'id', children: 'children' },
      pid: [],
      uploadUrl: process.env.VUE_APP_API_BASE_URL + 'common/upload',
      loading: false,
      mediaAccountList: [],
      showMediaList: false
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    appInfo: {
      default: () => ({}),
      type: Object
    }
  },
  watch: {},
  created () {
    this.getmediaAccountList() // 获取媒体账户下拉框
  },
  mounted () {
    if (!this.isAdd) {
      this.query = this.appInfo
      this.query.tradePid && (this.pid.push(this.query.tradePid)) && (this.pid.push(this.query.tradeId))
    }
    this.getIndustrySelect()
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token,
      title: (state) => state.autoweb.title,
      resourceUrl: (state) => state.autoweb.resourceUrl,
      isAdmin: (state) => state.user.isAdmin
    }),
    header () {
      return {
        Authorization: this.token
      }
    },
    addAppVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    // 获取媒体账户下拉框
    async getmediaAccountList () {
      const query = { dimension: '1' }
      const resp = await mediaAccountList(query)
      if (resp.code === 200) {
        const arr = resp.data || []
        this.showMediaList = true
        this.mediaAccountList = arr
      }
    },
    handleCancel () {
      this.addAppVisible = false
    },
    async getIndustrySelect () {
      const res = await industrySelect()
      if (res.code === 200) {
        this.industry = res.data
      }
    },
    changeTradeId (val) {
      if (val.length > 0) {
        this.query.tradePid = val[0]
        this.query.tradeId = val[1]
      }
    },
    beforeUpload (file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('请上传图片文件！')
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('请上传小于2M的图片!')
      }
      return isJpgOrPng && isLt2M
    },
    handleChange (info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        this.query.iconUrl = info.file.response.data
        this.loading = false
      }
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.isAdd) {
            const resp = await appAdd(this.query)
            if (resp.code === 200) {
              this.$message.success('添加成功!')
              this.$emit('changeAppInfo', 'add')
              this.addAppVisible = false
            }
          } else {
            const resp = await appUpdate(this.query)
            if (resp.code === 200) {
              this.$message.success('修改成功!')
              this.$emit('changeAppInfo', 'update')
              this.addAppVisible = false
            }
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less">
.addapp_modal .ant-modal-body {
  // background-color: #eee;
  .add_app_content {
    height: 50vh;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      box-shadow: inset 0 0 5px fade(@primary-color, 3%);
      background: fade(@primary-color, 20%);
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px fade(@primary-color, 3%);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
