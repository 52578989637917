<template>
  <a-modal
    v-model="bindAppVisible"
    title="关联应用"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    width="400px"
  >
    <a-form-model ref="ruleForm" :model="query" :rules="rules" v-bind="layout">
      <a-form-model-item prop="name" label="关联名" :rules="[{required: true,message:'请输入关联名', trigger: 'blur'}]">
        <a-input placeholder="请输入关联名" style="width: 250px" v-model.trim="query.name">
        </a-input>
      </a-form-model-item>
      <a-form-model-item prop="appId" label="目标应用" :rules="[{required: true,message:'请选择目标应用', trigger: 'change'}]">
        <m-select-one
          style="width: 250px"
          label="目标应用"
          :searchById="true"
          hasOs
          :hasPosition="false"
          :allData="appList"
          v-model="query.appId"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { getBindAppList, appBind } from '@/api/media'
export default {
  data () {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      query: {
        name: undefined,
        appId: undefined
      },
      rules: {},
      appList: []
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    appInfo: {
      default: () => {},
      type: Object
    }
  },
  watch: {
    visible: {
      async handler (val) {
        this.$nextTick(() => {
            this.$refs.ruleForm.clearValidate()
          })
        if (val) {
          this.query = {
            name: undefined,
            appId: undefined
          }
          await this.getBindAppList()
        }
      }
    }
  },
  computed: {
    bindAppVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    async getBindAppList () {
      const { data = [] } = await getBindAppList({
        os: this.appInfo.os,
        appId: this.appInfo.id
      })
      this.appList = data
    },
    handleCancel () {
      this.bindAppVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const { code = 200 } = await appBind({
            name: this.query.name,
            sourceAppId: this.appInfo.id,
            targetAppId: this.query.appId
          })
          if (code === 200) {
            this.bindAppVisible = false
            this.$message.success('关联成功！')
            this.$emit('bindSuccess')
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style></style>
