<template>
  <a-modal
    v-model="batchAddPlaceVisible"
    title="批量添加广告位"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    :width="1200"
  >
    <div class="batch-add-place-container">
      <a-button type="primary" @click="addPlaceList" style="margin: 10px"><a-icon type="plus" />添加广告位</a-button>
      <a-form-model ref="ruleForm" :model="query" :rules="rules" v-bind="layout">
        <a-table
          size="middle"
          :rowKey="(record, index) => index"
          :data-source="query.placeList"
          :pagination="false"
          :scroll="{ x: true }"
          bordered
        >
          <!-- 操作 -->
          <a-table-column align="center" title="操作" width="150px" :fixed="true">
            <template slot-scope="text, record, index">
              <div>
                <a-button type="link" @click="handleCopyItem(record)">复制</a-button>
                <a-button type="link" @click="handleDeleteItem(index)">删除</a-button>
              </div>
            </template>
          </a-table-column>
          <!-- 应用 -->
          <a-table-column align="left" title="应用">
            <template slot-scope="text, record, index">
              <a-form-model-item
                class="form_item"
                :prop="`placeList[${index}].appId`"
                :rules="[
                  {
                    required: true,
                    message: '请选择应用',
                    trigger: 'change'
                  }
                ]"
              >
                <m-select-one
                  allowClear
                  v-model="record.appId"
                  class="col_item"
                  label="应用"
                  :searchById="true"
                  hasOs
                  :hasPosition="false"
                  :allData="appList"
                  style="width: 220px"
                  @change="changeAppIdOrPosition(record.appId, index, record)"
                />
              </a-form-model-item>
            </template>
          </a-table-column>
          <!-- 广告样式 -->
          <a-table-column align="left" title="广告样式">
            <template slot-scope="text, record, index">
              <a-form-model-item
                class="form_item"
                :prop="`placeList[${index}].position`"
                :rules="[
                  {
                    required: true,
                    message: '请选择广告样式',
                    trigger: 'change'
                  }
                ]"
              >
                <a-select placeholder="请选择广告样式" v-model="record.position" style="width: 160px" @change="changeAppIdOrPosition(record.position, index, record)">
                  <a-select-option v-for="item in positionList" :key="item.id" :value="item.id">{{
                    item.name
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </template>
          </a-table-column>
          <!-- 广告位名称 -->
          <a-table-column class="table_item" align="left" title="广告位名称">
            <template slot-scope="text, record, index">
              <a-form-model-item
                :prop="`placeList[${index}].name`"
                :rules="[
                  {
                    required: true,
                    message: '请输入广告位名称',
                    trigger: 'blur'
                  }
                ]"
              >
                <a-input
                  placeholder="请输入广告位名称"
                  v-model.trim="record.name"
                  @input="handleChangeName($event, index, 'name', record)"
                  style="width: 180px"
                >
                </a-input>
              </a-form-model-item>
            </template>
          </a-table-column>
          <!-- 广告位状态 -->
          <a-table-column align="left" title="状态">
            <template slot-scope="text, record, index">
              <a-form-model-item class="form_item" :prop="`placeList[${index}].sts`">
                <a-switch :checked="record.sts === 'A'" @change="changeSts($event, index)" />
              </a-form-model-item>
            </template>
          </a-table-column>
        </a-table>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import mixGetList from '@/mixins/getList'
import { batchAddPlace } from '@/api/media'
export default {
  mixins: [mixGetList],
  data () {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      query: {},
      rules: {}
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    batchAddPlaceVisible: {
      handler (val) {
        if (val) {
          this.$nextTick(() => {
            this.$refs.ruleForm.clearValidate()
          })
          this.query = {
            placeList: [
              {
                appId: undefined,
                name: undefined,
                position: undefined,
                sts: 'A',
                nameHasChanged: false
              }
            ]
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  created () {
    this.getAppList()
    this.getPositionList()
  },
  computed: {
    batchAddPlaceVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    changeAppIdOrPosition (val, index, record) {
      if (!record.appId || !record.position) return
      if (record.nameHasChanged) return
      const appName = this.appList.find((item) => record.appId === item.id).name
      const os = this.appList.find((item) => record.appId === item.id).os
      const positionName = this.positionList.find((item) => record.position === item.id).name
      this.$set(record, 'name', `${appName}-${os ? '安卓' : 'IOS'}-${positionName}`)
    },
    changeSts (e, index) {
      this.query.placeList[index].sts = e ? 'A' : 'S'
    },
    handleChangeName (e, index, prop, record) {
      record.nameHasChanged = true
      this.query.placeList[index][prop] = e.target.value.replace(/[/\s+/]/g, '')
    },
    handleDeleteItem (index) {
      this.query.placeList.splice(index, 1)
    },
    addPlaceList () {
      this.query.placeList.push({
        appId: undefined,
        name: undefined,
        position: undefined,
        sts: 'A',
        nameHasChanged: false
      })
    },
    handleCopyItem (item) {
      this.query.placeList.push(JSON.parse(JSON.stringify(item)))
    },
    handleCancel () {
      this.batchAddPlaceVisible = false
    },
    handleSubmit () {
      if (this.query.placeList.length === 0) {
        return this.$message.warning('至少添加一个广告位')
      }
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const resp = await batchAddPlace(this.query.placeList)
          if (resp.code === 200) {
            this.$message.success('添加成功!')
            this.batchAddPlaceVisible = false
            this.$emit('changePlace')
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less">
.batch-add-place-container {
  padding: 20px;

  .ant-table-content {
    .ant-table-scroll .ant-table-body .ant-table-fixed .ant-table-tbody {
      tr {
        height: 90px;

        td {
          padding: 0 20px;

          .ant-form-item {
            display: flex;
            margin-bottom: 0;
            height: 40px;
            margin-top: 5px;

            .ant-form-item-control {
              line-height: 20px;
            }

            .ant-form-explain,
            .ant-form-extra {
              min-height: 16px;
              line-height: 16px;
              font-size: 12px;
            }

            .ant-select {
              padding: 0;
            }

            .ant-switch {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }

  .ant-table-fixed-left {
    tbody tr {
      height: 90px !important;
    }
  }
}
</style>
