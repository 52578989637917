<template>
  <div class="media-container fixed_container">
    <a-menu v-model="current" mode="horizontal" class="menu_group fixed_menu">
      <a-menu-item key="/media/app" v-if="allRoutes.includes('app')">
        <router-link :to="{ name: 'app' }">应用</router-link>
      </a-menu-item>
      <a-menu-item key="/media/adplace" v-if="allRoutes.includes('adplace')">
        <router-link :to="{ name: 'adplace' }">广告位</router-link>
      </a-menu-item>
      <a-menu-item key="/media/mediaalliance" v-if="allRoutes.includes('mediaalliance')">
        <router-link :to="{ name: 'mediaalliance' }"> 媒体联盟</router-link>
      </a-menu-item>
    </a-menu>
    <route-view></route-view>
  </div>
</template>
<script>
import { RouteView } from '@/layouts'
import { mapState } from 'vuex'
export default {
  name: 'Media',
  components: {
    RouteView
  },
  data () {
    return {
      current: []
    }
  },
  computed: {
    ...mapState({
      // 权限路由集合
      allRoutes: (state) => state.permission.allRoutes
    })
  },
  watch: {
    $route: {
      handler (val) {
        const routes = val.matched.concat()
        this.current = [routes.pop().path]
      },
      immediate: true
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep.media-container {
  background-color: #f7f7f7;
  // min-width: 1280px;
}
</style>
