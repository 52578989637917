<template>
  <div class="ad-header-container">
    <div class="left">
      <div class="ad-header-top">
        <span class="item">{{ appInfo.name || '' }}</span>
        <span class="line"></span>
        <span class="item">ANDROID & IOS</span>
        <a-button size="small" @click="handleClickUpdateApp" class="btn_item">编辑</a-button>
      </div>
      <div class="ad-header-bottom">
        <span
        >媒体联盟ID:{{ appInfo.appId
        }}<a-tooltip placement="bottom">
          <template slot="title">
            <span>复制应用ID</span>
          </template>
          <CopySvg @click="copyId(appInfo.appId)" style="cursor: pointer; margin-left: 5px"/> </a-tooltip
        ></span>
      </div>
    </div>
    <div class="right">
      <div class="right_con_left">
        <div>
          <span class="spanBox">今日消耗:</span>
          <i>{{ balance.costToday }}</i>
        </div>
        <a-spin :spinning="spinnings">
          <div class="chart" ref="chart"></div>
        </a-spin>
      </div>
      <div class="right_con_right" v-if="role !== 'SUPER_ADMIN'">
        <div>
          <span>账户余额:</span>
          <i>{{ balance.remaining }}</i>
        </div>
        <a-button @click="handleClickRecharge" class="button">立即充值</a-button>
      </div>
    </div>
    <!-- <div class="right">
      <a-button type="primary" @click="handleClickAddPlace">添加广告位</a-button>
    </div> -->
    <AppModal
      v-on="$listeners"
      v-if="addAppVisible"
      :appInfo="appInfo"
      :visible="addAppVisible"
      @modalCancel="addAppVisible = false"
      :isAdd="false"
    />
    <PlaceModal
      v-if="addPlaceVisible"
      :visible="addPlaceVisible"
      @modalCancel="addPlaceVisible = false"
      :isAdd="true"
      v-on="$listeners"
      :appId="appInfo.id"
    />
    <MediaModel
      @changeAppInfo="changeAppInfo"
      v-if="addMediaVisible"
      :appInfo="appInfo"
      :visible="addMediaVisible"
      @modalCancel="addMediaVisible = false"
    />
    <RechargeModel
      @changeAppInfo="changeAppInfo"
      v-if="rechargeModelVisible"
      :visible="rechargeModelVisible"
      @modalCancel="rechargeModelVisible = false"
    />
  </div>
</template>

<script>
import request from '@/utils/request'
import AppModal from '../components/appModal'
import PlaceModal from '../components/placeModal'
import { copy } from '@/utils/string'
import options from '../components/mediaModel/options'
import MediaModel from '../components/mediaModel'
import RechargeModel from '../components/rechargeModel'
import { mapState } from 'vuex'
import { numFormat } from '@/utils/dealNumber'
import { costAndRemaining } from '@/api/media'
import * as echarts from 'echarts'
import CopySvg from '@/assets/icons/copy.svg?inline'
export default {
  components: { AppModal, PlaceModal, MediaModel, RechargeModel, CopySvg },
  data () {
    return {
      addAppVisible: false,
      addPlaceVisible: false,
      addMediaVisible: false,
      rechargeModelVisible: false,
      options,
      myEcharts: null,
      spinnings: false,
      balance: {}
    }
  },
  props: {
    appInfo: {
      default: () => ({}),
      type: Object
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0]
    })
  },
  created () {
    this.costAndRemaining()
  },
  mounted () {},
  methods: {
    // 获取媒体联盟弹窗数据
    async costAndRemaining () {
      const res = await costAndRemaining()
      if (res.code === 200) {
        this.balance = res.data
        if (res.data.costToday) numFormat(res.data.costToday, 3, '')
        res.data.sevenDaysCost && this.getChartsData(res.data.sevenDaysCost)
      }
    },
    initChart () {
      setTimeout(() => {
        this.myEcharts = echarts.init(this.$refs.chart, null, { render: 'svg' })
        this.myEcharts.setOption(this.options)
        this.spinnings = false
      }, 500)
    },
    // 初始化echart
    async getChartsData (arr) {
      this.options.xAxis.data = arr.map((item) => item.date)
      this.options.series[0].data = arr.map((item) => item.cost)
      this.initChart()
    },
    // 复制
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    getOs (os) {
      return os === 0 ? 'IOS' : 'ANDROID'
    },
    // 编辑
    handleClickUpdateApp () {
      // this.addAppVisible = true
      this.addMediaVisible = true
    },
    // 立即充值
    handleClickRecharge () {
      this.rechargeModelVisible = true
    },
    // 删除
    handleDelete () {
      request.delete('/app?id=' + this.appInfo.id).then((resp) => {
        if (resp.code === 200) {
          this.$message.success('删除成功')
          this.$emit('changeAppInfo', 'delete')
        }
      })
    },
    // 添加广告位
    handleClickAddPlace () {
      this.addPlaceVisible = true
    },
    changeAppInfo () {
      this.$emit('changeAppInfo')
    }
  }
}
</script>
<style lang="less" scoped>
.ad-header-container {
  display: flex;
  justify-content: space-between;
  .left {
    .ad-header-top {
      height: 30px;
      line-height: 40px;
      .item {
        margin: 0 10px;
        color: #4d4d4d;
        font-size: 13px;
      }
      .line {
        display: inline-block;
        height: 12px;
        border-left: 2px solid #ccc;
      }
      .btn_item {
        margin: 0 5px 0 0;
      }
    }
    .ad-header-bottom {
      padding-left: 10px;
      font-size: 12px;
      line-height: 50px;
    }
  }
  .right {
    display: flex;
    .right_con_left,
    .right_con_right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 15px;
      width: 220px;
      height: 80px;
      border-radius: 10px;
      div {
        display: flex;
        flex-direction: column;
      }
      i {
        font-size: 20px;
        font-family: Arial-ItalicMT, Arial;
        font-weight: normal;
        color: #333333;
      }
    }
    .right_con_left {
      border: 2px solid #cae5ff;
      background: #f8feff;
      margin-right: 15px;
      .chart {
        height: 40px;
        width: 100px;
      }
    }
    .right_con_right {
      background: #f8f9ff;
      border: 2px solid #d1dfff;
      .button {
        width: 90px;
        height: 40px;
        background: @primary-color;
        border-radius: 10px;
        color: #fff;
      }
    }
  }
}
</style>
