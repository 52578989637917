<template>
  <div class="adplace-container">
    <div class="adplace_left">
      <div class="adplace_left_header">
        <div class="title">媒体联盟（{{ appList.length }}）</div>
        <!-- <div v-if="role !== 'SUPER_ADMIN'" class="add_app"><a-button
          type="primary"
          @click="handleClickAdd">添加媒体</a-button></div> -->
      </div>
      <div class="adplace_left_search">
        <a-input class="m_input_cust" placeholder="按媒体联盟名称和ID搜索" v-model.trim="searchKey">
          <a-icon slot="suffix" type="search" />
        </a-input>
      </div>
      <div class="adplace_left_main" :style="{ overflowY: filterList.length > 0 ? 'auto' : '' }">
        <a-menu
          v-if="filterList.length > 0"
          :selectedKeys="currentApp"
          style="max-height: 500px"
          type="inner"
          @select="handleSelect"
        >
          <a-menu-item v-for="item in filterList" :key="item.appId">
            <div class="adplace_main_menu_item" :title="item.name">
              <div class="img">
                <img :src="item.iconUrl ? item.iconUrl : '/images/app-none.png'" alt="" />
              </div>
              <div class="desc">
                <span class="name">
                  {{ item.name }}</span
                  >
                <span class="id">ID:{{ item.appId }}</span>
              </div>
            </div>
          </a-menu-item>
        </a-menu>
        <m-empty v-else imgHeight="100px">
          <template #description> 暂无媒体联盟 </template>
        </m-empty>
      </div>
    </div>
    <div class="adplace_right">
      <AdMain v-if="appList.length > 0" :appInfo="currentAppInfo" @changeAppInfo="changeAppInfo" />
      <m-empty v-else>
        <template #description> 暂无媒体联盟</template>
      </m-empty>
    </div>
    <AppModal
      @changeAppInfo="changeAppInfo"
      v-if="addAppVisible"
      :visible="addAppVisible"
      @modalCancel="addAppVisible = false"
      isAdd
    />
    <MediaModel
      @changeAppInfo="changeAppInfo"
      v-if="addMediaVisible"
      :visible="addMediaVisible"
      @modalCancel="addMediaVisible = false"
      isAdd
    />
  </div>
</template>
<script>
import {
  // getAllAppPage,
  mediaLeagueList
} from '@/api/media'
import { mapState } from 'vuex'
import AdMain from './adMain'
import AppModal from './components/appModal'
import MediaModel from './components/mediaModel'
import IosSvg from '@/assets/icons/ios.svg?inline'
import AndroidSvg from '@/assets/icons/android.svg?inline'
export default {
  name: 'MediaAlliance',
  components: { AdMain, AppModal, IosSvg, AndroidSvg, MediaModel },
  data () {
    return {
      appList: [],
      currentApp: [],
      searchKey: '',
      operationType: '',
      addAppVisible: false,
      addMediaVisible: false
    }
  },
  computed: {
    currentAppInfo () {
      return this.appList.filter((item) => item.appId === this.currentApp[0])[0]
    },
    filterList () {
      return this.appList?.filter(
        (item) => item?.name?.includes(this.searchKey) || item?.appId?.includes(this.searchKey)
      )
    },
    ...mapState({
      resourceUrl: (state) => state.autoweb.resourceUrl,
      role: (state) => state.user.roles[0]
    })
  },
  mounted () {
    this.mediaLeagueList()
  },
  methods: {
    handleSelect ({ key }) {
      this.currentApp = [key]
    },
    getCreativeUrl (url) {
      if (url) {
        return this.resourceUrl + url
      } else {
        return null
      }
    },
    // 获取列表
    async mediaLeagueList () {
      const resp = await mediaLeagueList()
      this.appList = resp.data || []
      const firstApp = this.appList.length > 0 ? this.appList[0] : ''
      if (firstApp) {
        if (
          this.operationType === 'delete' ||
          this.operationType === '' ||
          this.operationType === 'add' ||
          this.operationType === undefined
        ) {
          this.currentApp = [firstApp.appId]
        }
      }
      this.operationType = ''
    },
    changeAppInfo (type) {
      this.operationType = type
      this.mediaLeagueList()
    }
    // handleClickAdd () {
    //   this.addMediaVisible = true
    //   // this.addAppVisible = true
    // }
  }
}
</script>

<style lang="less" scoped>
.adplace-container {
  position: relative;
  display: flex;
  background: #f7f7f7;
  padding: 0 10px;
  min-height: calc(100vh - 130px);

  .adplace_left {
    border-radius: 5px;
    max-width: 300px;
    min-width: 260px;
    width: 18%;
    background: #fff;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    min-height: 620px;
    padding: 10px 20px;

    .adplace_left_header {
      height: 60px;
      line-height: 60px;
      display: flex;
      justify-content: space-between;
    }

    .adplace_left_search {
      height: 50px;
      padding-top: 10px;
      margin-bottom: 10px;
    }

    .adplace_left_main {
      box-sizing: border-box;
      padding: 5px 0;
      flex-grow: 1;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        box-shadow: inset 0 0 5px fade(@primary-color, 3%);
        background: fade(@primary-color, 20%);
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px fade(@primary-color, 3%);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      }

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        box-shadow: inset 0 0 5px fade(@primary-color, 3%);
        background: fade(@primary-color, 20%);
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px fade(@primary-color, 3%);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      }

      .ant-menu.ant-menu-vertical.ant-menu-root.ant-menu-light {
        border: none;

        .ant-menu-item {
          height: 60px;
          line-height: 60px;
          color: #999999;

          &.ant-menu-item-selected {
            background-color: fade(@primary-color,  5%);
            color: @primary-color;
            font-weight: 600;

            &::before {
              content: '';
              height: 100%;
              position: absolute;
              left: 0;
              border-radius: 5px;
              border-left: 3px solid @primary-color;
            }
          }
        }
      }

      .adplace_main_menu_item {
        margin-bottom: 10px;
        display: flex;
        height: 60px;

        .img {
          width: 40px;
          height: 40px;

          img {
            width: 40px;
            margin: 0;
            padding: 0;
            height: 40px;
          }
        }

        .desc {
          margin-left: 4px;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          border: 1px;

          .name {
            height: 35px;
            line-height: 40px;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .id {
            flex-grow: 1;
            line-height: 14px;
            font-size: 12px;
          }
        }
      }
    }
  }

  .adplace_right {
    flex-grow: 1;
  }

  ::v-deep {
    .ad-header-container {
      padding: 20px 20px 0 20px;
    }

    .m_table_cust {
      padding: 0 10px 10px 10px;
    }

    .empty_box {
      border-radius: 5px;
      margin: 0;
    }
  }
}
</style>
