<template>
  <a-modal v-model="demoAuditVisible" title="素材替换" @onCancel="handleCancel" @ok="handleSubmit" :width="900">
    <div class="demo-audit-container">
      <a-form-model ref="ruleForm" :model="formquery" :rules="rules" v-bind="layout">
        <!-- 状态 -->
        <a-form-model-item label="状态" prop="replaceSts">
          <a-radio-group v-model="formquery.replaceSts">
            <a-radio value="A"> 开启 </a-radio>
            <a-radio value="S"> 关闭 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <!-- 替换策略 -->
        <a-form-model-item label="替换策略" prop="replaceStrategySts">
          <a-radio-group v-model="formquery.replaceStrategySts">
            <a-radio value="A"> 开启 </a-radio>
            <a-radio value="S"> 关闭 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <!-- 时间 -->
        <a-form-model-item
          class="form_item"
          label="时间策略"
          prop="timeVal"
          v-if="formquery.replaceStrategySts === 'A'"
        >
          <a-row type="flex">
            <a-col :span="24" v-for="(item, index) in timeVal" :key="index + 1" style="justify-content: flex-start">
              <a-select v-model="timeSelect" :disabled="index !== 0" style="width: 28%; display: inline-block">
                <a-select-option v-for="child in options1" :key="child.value" :value="child.value">{{
                  child.label
                }}</a-select-option>
              </a-select>
              <a-time-picker
                style="margin-right: 12px; width: 27%"
                v-model="timeVal[index][0]"
                format="HH:mm"
                clearText
              />
              <span>至</span>
              <a-time-picker
                style="margin-left: 12px; width: 27%"
                v-model="timeVal[index][1]"
                format="HH:mm"
                clearText
              />
              <span>
                <a-button
                  type="link"
                  style="font-size: 12px; margin-left: 10px; cursor: pointer"
                  @click="handleCloseTimeItem(index)"
                ><a-icon
                  type="close-circle"
                /></a-button>
              </span>
            </a-col>
          </a-row>
          <a-button
            style="padding: 0 0 10px 0"
            type="link"
            @click="handleAddTimeItem"
          ><a-icon type="plus" />添加时间规则</a-button
          >
        </a-form-model-item>
        <!-- 替换内容 -->
        <a-form-model-item label="替换内容" prop="creativeVoList">
          <div
            v-for="(record, index) in formquery.creativeVoList"
            :key="index + 1"
            class="demo-rule"
            :class="{ 'last-demo': index + 1 === formquery.creativeVoList.length }"
          >
            <!-- 广告平台 -->
            <a-form-model-item
              class="form_item"
              :prop="`creativeVoList[${index}].advId`"
              :rules="[
                {
                  required: true,
                  message: '请选择广告平台',
                  trigger: 'change'
                }
              ]"
            >
              <m-select-one
                v-if="show"
                allowClear
                v-model="record.advId"
                class="col_item"
                label="广告平台"
                :searchById="true"
                :showId="false"
                hasOs
                :hasPosition="false"
                :allData="platList"
                style="width: 200px"
              />
            </a-form-model-item>
            <!-- 素材ID -->
            <a-form-model-item
              :prop="`creativeVoList[${index}].id`"
              :rules="[
                {
                  required: true,
                  message: '请输入素材ID',
                  trigger: 'blur'
                }
              ]"
            >
              <a-input
                placeholder="请输入素材ID"
                v-model.trim="record.id"
                @input="handleChangeName($event, index, 'id')"
                style="width: 200px"
              >
              </a-input>
            </a-form-model-item>
            <!--素材链接  -->
            <a-form-model-item
              :prop="`creativeVoList[${index}].creativeUrl`"
              :rules="[
                {
                  required: true,
                  message: '请输入素材链接',
                  trigger: 'blur'
                }
              ]"
            >
              <a-input
                placeholder="请输入素材链接"
                v-model.trim="record.creativeUrl"
                @input="handleChangeName($event, index, 'creativeUrl')"
                style="width: 220px"
              >
              </a-input>
            </a-form-model-item>
            <a-button type="link" @click="handleDeleteContent(index)"> <a-icon type="close-circle" /></a-button>
          </div>
          <a-button
            style="padding: 0 10px 0 0"
            type="link"
            @click="handleAddContent"
          ><a-icon type="plus" />添加替换内容</a-button
          >
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import moment from 'moment'
import { getRtbPlatformList } from '@/api/common'
import { updateCreativeReplaceSetting, getCreativeReplaceSetting } from '@/api/advertisement'
export default {
  data () {
    return {
      layout: {
        labelCol: { span: 3 },
        wrapperCol: { span: 19 }
      },
      options1: [
        {
          value: 0,
          label: '包括'
        }
      ],
      timeSelect: 0, // 时间选择方式
      timeVal: [[null, null]], // 时间
      formquery: {
        replaceSts: 'S',
        replaceStrategySts: 'A',
        placeId: '',
        // 素材替换内容
        creativeVoList: [
          {
            id: undefined,
            creativeUrl: undefined,
            advId: undefined
          }
        ],
        ruleList: []
      },
      show: false,
      platList: [],
      rules: {}
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    datax: {
      default: () => ({}),
      type: Object
    }
  },
  watch: {
    demoAuditVisible: {
      handler (val) {
        if (val) {
          this.formquery.placeId = this.datax.id
          this.getPlatList()
          this.getData()
          this.$nextTick(() => {
            this.$refs.ruleForm.clearValidate()
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  async created () {
    this.formquery.placeId = this.datax.id
    await this.getPlatList()
  },
  computed: {
    demoAuditVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    // 广告平台
    async getPlatList () {
      const { data = [] } = await getRtbPlatformList()
      this.platList = data
      if (data.length === 0) {
        return
      }
      data.forEach((item) => {
        item.id = '' + item.id
      })
      this.show = true
    },
    // 回显数据
    async getData () {
      const resp = await getCreativeReplaceSetting({
        placeId: this.formquery.placeId
      })
      if (resp.code === 200) {
        resp.data.creativeVoList.forEach((item) => {
          item.advId = '' + item.advId
        })
        this.formquery = resp.data
        this.getFormInfo()
      }
    },
    // 时间获取
    getFormInfo () {
      this.formquery.ruleList.map((x) => {
        this.timeVal = x.ruleContent.map((item) => {
          const timeStrArr = item.split('-')
          return [moment(timeStrArr[0], 'HH:mm'), moment(timeStrArr[1], 'HH:mm')]
        })
        this.timeSelect = x.ruleType
      })
    },
    // 时间规则
    getRuleObj () {
      const timeRuleContent = this.timeVal
        .filter((item) => item[0] && item[1])
        .map((item) => {
          const time1 = item[0].format('HH:mm')
          const time2 = item[1].format('HH:mm')
          return time1 + '-' + time2
        })
      if (timeRuleContent.length === 0) {
        return false
      }
      return {
        ruleType: this.timeSelect,
        ruleContent: timeRuleContent
      }
    },
    // 添加替换内容
    handleAddContent () {
      this.formquery.creativeVoList.push({
        id: undefined,
        creativeUrl: undefined,
        advId: undefined
      })
    },
    // 删除素材内容
    handleDeleteContent (index) {
      this.formquery.creativeVoList.splice(index, 1)
    },
    // 请输入素材ID//素材链接
    handleChangeName (e, index, prop) {
      // this.formquery.creativeVoList[index][prop] = e.target.value.replace(/[\u4e00-\u9fa5/\s+/]/g, '')
      // 保留网址的//
      this.formquery.creativeVoList[index][prop] = e.target.value.replace(/[\u4e00-\u9fa5\s+]/g, '')
    },
    // 添加时间子规则
    handleAddTimeItem () {
      this.timeVal.push([null, null])
    },
    // 删除时间子规则
    handleCloseTimeItem (index) {
      this.timeVal.splice(index, 1)
    },
    handleCancel () {
      this.demoAuditVisible = false
    },
    handleSubmit () {
      if (this.formquery.creativeVoList.length === 0) {
        return this.$message.warning('至少添加一个替换内容')
      }
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const { ruleType, ruleContent } = this.getRuleObj()
          if (this.formquery.replaceStrategySts === 'A' && ruleContent?.length > 0) {
            this.formquery.ruleList = []
            this.formquery.ruleList.push({
              ruleId: 6,
              ruleType,
              ruleContent
            })
          } else {
            this.formquery.ruleList = []
          }
          this.formquery.creativeVoList.map((x) => {
            x.advId = +x.advId
          })
          this.formquery.placeId = this.datax.id
          const resp = await updateCreativeReplaceSetting(this.formquery)
          if (resp.code === 200) {
            this.$message.success('添加成功!')
            this.demoAuditVisible = false
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep.demo-audit-container .ant-select {
  padding-top: 2px;
}
.demo-audit-container {
  padding: 10px;
  .demo-rule {
    height: 58px;
    display: flex;
    justify-content: flex-start;
    &.last-demo {
      margin-bottom: -15px;
    }
  }
}
</style>
