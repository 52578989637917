<template>
  <div class="ad-main-container">
    <div class="search_box">
      <m-select-more
        v-if="showApp"
        class="search_item"
        v-model="query.appIds"
        @change="getPlaceList"
        :allData="appList"
        :searchById="true"
        :hasIcon="true"
        :showId="true"
        label="应用"
      />
      <m-select-more
        class="search_item"
        v-model="query.adplaceIds"
        :allData="placeList"
        :searchById="true"
        :hasIcon="true"
        :showId="true"
        label="广告位"
      />
      <m-select-more
        class="search_item"
        v-model="query.positionIds"
        :allData="positionList"
        :searchById="false"
        :hasIcon="false"
        :showId="false"
        label="广告样式"
        :showSelectedPart="true"
        :canSelectAll="true"
      />
      <a-select class="search_item" v-model="query.sts" allowClear placeholder="选择状态">
        <a-select-option v-for="item in auditStsList" :key="item.id">
          {{ item.name }}
        </a-select-option>
      </a-select>
      <a-range-picker
        v-if="roleName !== '媒体'"
        format="YYYY-MM-DD"
        :placeholder="['Start', 'End']"
        style="width: 230px"
        :value="rangePickerDate"
        @change="ChangeDate"
        :allowClear="false"
        :ranges="{
          今日: [initDate(0, 0).start, initDate(0, 0).end],
          昨日: [initDate(1, 1).start, initDate(1, 1).end],
          最近一周: [initDate(6, 0).start, initDate(6, 0).end],
          最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
        }"
        :disabledDate="disabledDate"
      >
      </a-range-picker>
      <a-button class="SearchAd" type="primary" @click="getData()">查询</a-button>
      <!-- <a-button type="primary" class="addAppBtn" @click="test">export</a-button> -->
    </div>
    <div class="btn-wrapper">
      <div>
        <!-- <a-button class="add_place" type="primary" @click="handleClickAddPlace"><a-icon type="plus" />广告位</a-button>
        <a-button class="add_place" @click="handleBatchAddPlace"> <a-icon type="plus-square" />批量添加</a-button> -->
        <a-dropdown-button
          :trigger="['click']"
          type="primary"
          style="border-radius: 0px !important"
          @click="handleClickAddPlace"
        >
          <a-icon type="plus" />
          广告位
          <a-menu slot="overlay">
            <a-menu-item style="width: 130px" key="2" @click="handleBatchAddPlace">
              <a-icon type="plus-square" />批量添加
            </a-menu-item>
          </a-menu>
        </a-dropdown-button>
      </div>
      <a-button
        type="link"
        @click="handleClickCustTags"
        v-if="roleName !== '媒体'"
      ><a-icon type="edit" />自定义指标</a-button
      >
    </div>

    <div class="table">
      <a-table
        @change="tableChange"
        :locale="tablenodata"
        class="m_table_cust"
        :pagination="false"
        :scroll="{ x: true }"
        rowKey="id"
        :columns="columns"
        :data-source="dataList"
        :loading="loading"
        bordered
        id="placeTable"
      >
        <span slot="position" slot-scope="pos">{{ getPosition(pos) }}</span>
        <div class="slot_application" slot="appName" slot-scope="appName, row">
          <div class="img">
            <img :src="row.iconUrl ? getCreativeUrl(row.iconUrl) : '/images/app-none.png'" alt="" />
          </div>
          <div class="desc">
            <span class="name">
              <IosSvg v-if="row.os === 0" class="svg-icon" />
              <AndroidSvg v-else class="svg-icon" />{{ row.appName }}
            </span>
            <span
              style="font-size: 12px; line-height: 20px; color: #999"
            >ID:{{ row.appId }}
              <a-tooltip placement="bottom">
                <template slot="title">
                  <span>复制应用ID</span>
                </template>
                <CopySvg @click="copyId(row.appId)" style="cursor: pointer; margin-left: 5px"/>
              </a-tooltip>
            </span>
          </div>
        </div>
        <div class="slot_place" slot="place" slot-scope="name, row">
          <div class="name">{{ row.name || '' }}</div>
          <div class="id">
            ID:{{ row.id
            }}<a-tooltip placement="bottom">
              <template slot="title">
                <span>复制广告位ID</span>
              </template>
              <CopySvg @click="copyId(row.id)" style="cursor: pointer; margin-left: 5px"/>
            </a-tooltip>
          </div>
        </div>
        <div slot="sts" slot-scope="row">
          <a-switch size="small" default-checked @change="handleChangeSts($event, row)" :checked="row.sts === 'A'" />
        </div>
        <div slot="platNum" slot-scope="row">{{ row.platNum }}个</div>
        <div slot="placeDau" slot-scope="placeDau">{{ numFormat(Math.floor(placeDau / dauDays), 3) }}</div>
        <div class="slot_action" slot="action" slot-scope="row, record, index">
          <template v-if="+row.apiSource !== 2">
            <span class="btn" @click="handleClickUpdatePlace(row)">编辑</span>
            <span class="btn" @click="toAggregate(row)" v-if="allRoutes.includes('aggregate')">
              <span style="color: #ccc">|</span>
              聚合管理</span
            >
            <a-popover
              :ref="getRefName(index)"
              v-if="+isAdmin !== 2"
              overlayClassName="place_setting_popover"
              trigger="hover"
              placement="right"
            >
              <img
                style="margin: 2px 0 4px 0px; cursor: pointer; width: 3px; height: 13px"
                src="../../../assets/images/more.png"
                alt=""
              />
              <div slot="content">
                <a-popconfirm
                  v-if="+isAdmin !== 2"
                  title="确认删除该广告位?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="handleDelete(row)"
                >
                  <div class="btnSetting">
                    <a-icon class="delete_icon icon" type="delete" />
                    <span style="color: #333333">删除</span>
                  </div>
                </a-popconfirm>
                <div
                  v-if="isSuperAdmin || (adminUserId != null && +adminUserId == 1)"
                  class="btnSetting"
                  @click="toDemo(row, index)"
                >
                  <img
                    style="margin: 0px 2px 4px 0px; color: white; width: 15px; height: 15px"
                    src="../../../assets/images/demoAudit.png"
                    alt=""
                  />
                  <span style="margin-left: 10px; color: #333333">素材替换</span>
                </div>
              </div>
            </a-popover>
          </template>
          <template v-else>--</template>
        </div>
        <div slot="deu" slot-scope="deu">
          {{ numFormat(Math.floor(deu / dauDays), 3) }}
        </div>
        <div slot="permeability" slot-scope="permeability">{{ permeability }}%</div>
        <div slot="requestFilledRate" slot-scope="requestFilledRate">{{ requestFilledRate }}%</div>
        <div slot="impressRate" slot-scope="impressRate">{{ impressRate }}%</div>
        <div slot="clickRate" slot-scope="clickRate">{{ clickRate }}%</div>
        <div slot="impressRequestRate" slot-scope="impressRequestRate">{{ impressRequestRate }}%</div>
      </a-table>
      <m-empty style="height: 70vh" v-if="dataList.length === 0">
        <template #description> 暂时没有任何数据 </template>
      </m-empty>
      <a-pagination
        class="pagination"
        v-if="dataList.length > 0"
        show-size-changer
        :current="query.page"
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        @change="handleChangePage"
        @showSizeChange="onShowSizeChange"
        :pageSize="query.limit"
      />
    </div>
    <PlaceModal
      v-if="addPlaceVisible"
      :placeInfo="placeInfo"
      :addAppId="addAppId"
      :visible="addPlaceVisible"
      @modalCancel="addPlaceVisible = false"
      :isAdd="isAdd"
      @changePlace="changePlace"
    />
    <batchAddPlace
      :visible="batchAddPlaceVisible"
      @modalCancel="batchAddPlaceVisible = false"
      v-on="$listeners"
      @changePlace="changePlace"
    />
    <demoAudit
      v-if="demoAuditVisible"
      :datax="datax"
      :visible="demoAuditVisible"
      @modalCancel="demoAuditVisible = false"
    />
    <CustTagsModal
      :targetArr="targetArr"
      :sureArr="sureArr"
      :storageName="storageName"
      :visible="custTagsVisible"
      @modalCancel="custTagsVisible = false"
      @changeCustTags="changeCustTags"
      :defaultCheckedList="defaultCheckedList"
    />
  </div>
</template>

<script>
import { getAllPlacePage, placeUpdate, getAllAppPage } from '@/api/media'
import { getPositionList, getPlaceListByAppIds, getPlaceList } from '@/api/common'
import PlaceModal from '../components/placeModal'
import batchAddPlace from '../components/batchAddPlaceModal'
import demoAudit from '../components/demoAudit'
import request from '@/utils/request'
import { copy } from '@/utils/string'
import { mapState } from 'vuex'
import mixGetList from '@/mixins/getList'
import options from './options'
import IosSvg from '@/assets/icons/ios.svg?inline'
import AndroidSvg from '@/assets/icons/android.svg?inline'
import CustTagsModal from '@/components/common/CustTagsModal'
import custTagObj from './tagList'
import mixDate from '@/mixins/initDate'
import { getDuration, getDays } from '@/utils/duration'
import { numFormat } from '@/utils/dealNumber'
import CopySvg from '@/assets/icons/copy.svg?inline'

const baseColumns = [
  {
    dataIndex: 'appName',
    title: '应用',
    scopedSlots: { customRender: 'appName' },
    fixed: 'left',
    width: 250
  },
  {
    dataIndex: 'name',
    title: '广告位',
    scopedSlots: { customRender: 'place' },
    fixed: 'left',
    width: 200
  },
  {
    dataIndex: 'position',
    title: '广告样式',
    scopedSlots: { customRender: 'position' },
    fixed: 'left',
    width: 130
  },
  {
    dataIndex: '',
    title: '状态',
    scopedSlots: { customRender: 'sts' },
    fixed: 'left',
    width: 80
  },
  {
    dataIndex: '',
    title: '操作',
    scopedSlots: { customRender: 'action' },
    fixed: 'left',
    width: 200
  }
]
export default {
  name: 'Adplace',
  mixins: [mixGetList, mixDate],
  components: { PlaceModal, batchAddPlace, demoAudit, IosSvg, AndroidSvg, CustTagsModal, CopySvg },
  data () {
    return {
      allCustProp: [],
      batchAddPlaceVisible: false, // 批量添加
      demoAuditVisible: false, // 素材审核
      options,
      addAppId: '',
      addPlaceVisible: false,
      isAdd: false,
      placeInfo: {},
      loading: false,
      datax: undefined,
      showApp: false,
      columns: [],
      appList: [],
      query: {
        endDate: undefined,
        beginDate: undefined,
        page: 1,
        limit: 10,
        appIds: [],
        adplaceIds: undefined,
        positionIds: undefined,
        sortBy: 9,
        sortOrder: 'desc'
      },
      rangePickerDate: null, // 日期
      total: 0,
      positionList: [],
      placeList: [],
      tablenodata: {
        // emptyText: '暂无数据'
      },
      auditStsList: [
        { name: '启用', id: 'A' },
        { name: '暂停', id: 'S' }
      ],
      spinning: false,
      dataList: [],
      defaultCheckedList: [],
      targetArr: [],
      sureArr: [],
      storageName: 'placeData_0418',
      custTagsVisible: false,
      dauDays: 0
    }
  },
  watch: {
    // 路由传参
    $route: {
      handler (val) {
        if (val.query.appId) {
          this.query.appIds = [val.query.appId]
        }
      },
      immediate: true,
      deep: true
    },
    'query.appIds': {
      handler (val) {
        if (val) {
          // 应用选中一项时候,ID带进去广告位
          this.addAppId = val.length === 1 ? this.query.appIds[0] : ''
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.user.isAdmin,
      // 动态主路由
      allRoutes: (state) => state.permission.allRoutes,
      roleName: (state) => state.user.info.roleName,
      // 超管
      isSuperAdmin: (state) => state.user.roles && state.user.roles[0] === 'SUPER_ADMIN',
      adminUserId: (state) => state.user.adminUserId,
      // 图片加载
      resourceUrl: (state) => state.autoweb.resourceUrl
    })
  },
  async created () {
    // 初始化时间
    const { endDate, startDate, end, start } = this.initDate(7, 1)
    this.rangePickerDate = [start, end]
    this.query.endDate = endDate
    this.query.beginDate = startDate
    this.initColumns()
    this.getPositionList()
    this.getAppList()
    // 初始化广告位列表
    await this.getPlaceList()
    this.getData()
  },
  methods: {
    getRefName (index) {
      return 'actionSetting' + index
    },
    numFormat,
    async getPlaceList () {
      const res = this.query.appIds.length > 0 ? await getPlaceListByAppIds(this.query.appIds) : await getPlaceList()
      this.placeList = res.data
    },
    tableChange (pagination, filters, sorter) {
      // 排序属性对应值
      const sortByObj = {
        placeDau: 1,
        deu: 2,
        permeability: 3,
        aipau: 4,
        aipu: 5,
        estimateArpu: 6,
        income: 7,
        estimatedRevenueEcpm: 8,
        request: 9,
        requestFilledRate: 10,
        impress: 11,
        impressRate: 12,
        click: 13
      }
      this.query.sortOrder = sorter.order === 'descend' ? 'desc' : sorter.order === 'ascend' ? 'asc' : undefined
      this.query.sortBy = this.query.sortOrder ? sortByObj[sorter.field] : undefined
      this.getData()
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    // 时间修改
    ChangeDate (date, dateString) {
      this.rangePickerDate = dateString
      const [dateStart, dateEnd] = dateString
      this.query.beginDate = dateStart
      this.query.endDate = dateEnd
    },
    changeCustTags (targetArr, sureArr) {
      this.targetArr = targetArr
      this.sureArr = sureArr
      this.getColumns()
    },
    handleClickCustTags () {
      this.custTagsVisible = true
    },
    initColumns () {
      const tagInfo = localStorage.getItem(this.storageName)
      const { sureArr, targetArr } = custTagObj
      this.defaultCheckedList = JSON.parse(JSON.stringify(sureArr))
      if (!tagInfo) {
        this.targetArr = JSON.parse(JSON.stringify(targetArr))
        this.sureArr = JSON.parse(JSON.stringify(sureArr))
      } else {
        this.targetArr = JSON.parse(tagInfo).targetArr
        this.sureArr = JSON.parse(tagInfo).sureArr
      }
      custTagObj.targetArr.forEach((item) => {
        item.list.forEach((child) => {
          this.allCustProp.push(child.prop)
        })
      })
      this.getColumns()
    },
    getColumns () {
      const sureArr = this.sureArr.map((item) => ({
        title: (
          <div>
            <m-tips showIcon={false} content={item.desc} title={item.label} width={150}></m-tips>
          </div>
        ),
        dataIndex: item.prop,
        // width: item.width,
        align: 'center',
        scopedSlots: { customRender: item.prop },
        sortDirections: ['descend', 'ascend'],
        sorter: item.sorter
      }))
      if (this.roleName !== '媒体') {
        this.columns = [...baseColumns, ...sureArr]
      } else {
        this.columns = baseColumns.map((item) => ({
          ...item,
          fixed: undefined,
          align: 'left'
        }))
      }
    },
    async getAppList () {
      const resp = await getAllAppPage()
      this.appList = resp.data[0] || []
      this.showApp = true
    },
    getCreativeUrl (url) {
      if (url) {
        return this.resourceUrl + url
      } else {
        return null
      }
    },
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    // 编辑
    handleClickUpdatePlace (row) {
      this.placeInfo = row
      this.isAdd = false
      this.addPlaceVisible = true
    },
    getDays,
    async getData () {
      this.loading = true
      if (this.query.beginDate) {
        this.dauDays = this.getDays(this.query.beginDate, this.query.endDate)
      }
      const resp = await getAllPlacePage(this.query)
      if (resp.code === 200) {
        this.dataList = resp.data.items || []
        this.total = resp.data.total
        this.dataList.forEach((item, i) => {
          for (const key in item) {
            if (key !== 'deu' && key !== 'placeDau' && this.allCustProp.includes(key)) {
              item[key] = numFormat(+item[key] || 0, 3)
            }
          }
        })
        this.loading = false
        this.spinning = true
      }
    },
    async getPositionList () {
      const resp = await getPositionList()
      this.positionList = resp.data || []
    },
    getPosition (pos) {
      const positionInfo = this.positionList?.find((item) => Number(item.id) === pos)
      if (positionInfo) {
        return positionInfo.name
      } else {
        return ''
      }
    },
    // 状态
    async handleChangeSts (sts, row) {
      this.loading = true
      row.sts = sts ? 'A' : 'S'
      const resp = await placeUpdate(row)
      if (resp.code === 400) {
        this.getData()
      }
      if (resp.code === 200) {
        this.$message.success('修改成功！')
        this.getData()
      }
      this.loading = false
    },
    handleDelete (row) {
      request.get('/place?id=' + row.id).then((resp) => {
        if (resp.code === 200) {
          this.$message.success('删除成功')
          this.getData()
        }
      })
    },
    toAggregate (row) {
      this.$router.push({
        path: '/aggregate/config',
        query: {
          appId: row.appId,
          placeId: row.id
        }
      })
    },
    // 展开素材审核弹窗
    toDemo (row, index) {
      this.datax = row
      this.demoAuditVisible = true
      this.$nextTick(() => {
        this.$refs['actionSetting' + index].$children[0].sVisible = false
      })
    },
    handleBatchAddPlace () {
      this.batchAddPlaceVisible = true
    },
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getData()
    },
    handleChangePage (page) {
      this.query.page = page
      this.getData()
    },
    changePlace () {
      this.getPlaceList()
      this.getData()
    },
    // 点击添加广告位
    handleClickAddPlace () {
      this.isAdd = true
      this.addPlaceVisible = true
    }
  }
}
</script>
<style lang="less" scoped>
.ad-main-container {
  position: relative;
  border-radius: 5px;
  min-height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 0 10px;

  .search_box {
    padding-bottom: 20px;

    .ad {
      padding: 0 5px;
      width: 100px;
      float: right;
      margin-right: 80px;
    }

    .search_item {
      padding: 0 5px;
      margin-bottom: 5px;
      width: 230px;
      display: inline-block;
    }

    .SearchAd {
      width: 80px;
      display: inline-block;
      background: fade(@primary-color, 10%);
      border: 1px solid @primary-color;
      color: @primary-color;
      margin-left: 10px;
    }
  }

  .m_table_cust {
    padding: 0;
  }

  .table {
    flex-grow: 1;
    background: #fff;

    .slot_place {
      .name {
        font-size: 13px;
        line-height: 20px;
        color: #333;
      }

      .id {
        font-size: 12px;
        line-height: 20px;
        color: #999;
      }
    }

    .slot_action {
      span {
        font-size: 14px;
        margin-right: 10px;
      }
      .btn {
        cursor: pointer;
        color: @primary-color;
      }
      .line {
        color: #ccc;
      }
    }
  }

  .pagination {
    margin: 20px 0;
  }
}
.slot_application {
  display: flex;
  align-items: center;

  .img {
    width: 40px;
    height: 40px;
    margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }

  .desc {
    margin-left: 4px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;

    .name {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;

      .svg-icon {
        margin-right: 3px;
        height: 13px;
        width: 13px;
      }
    }
  }

  .slot_place {
    display: flex;
    align-items: center;

    .img {
      width: 40px;
      height: 40px;
      margin-right: 10px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }

    .desc {
      margin-left: 4px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      position: relative;

      .name {
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;

        .svg-icon {
          margin-right: 3px;
        }
      }

      .id {
        flex-grow: 1;
        line-height: 14px;
        font-size: 12px;
      }
    }

    .id {
      font-size: 13px;
      line-height: 20px;
      color: #999;
    }
  }
}
.btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  .add_place {
    margin-right: 10px;
  }
}
.btnSetting {
  height: 24px;
  cursor: pointer;
  color: #333333;
  margin-right: 10px;
  line-height: 24px;
  cursor: pointer;
  padding: 0 5px;

  &:hover {
    background: fade(@primary-color,  5%);
  }

  .icon {
    margin-right: 14px;

    &.setting_icon {
      color: @primary-color;
    }

    &.delete_icon {
      color: red;
    }

    &.waring_icon {
      color: orange;
    }
  }
}
::v-deep {
  .ant-btn.ant-btn-primary.ant-dropdown-trigger {
    border-radius: 0 5px 5px 0;
    height: 36px;
  }
  .ant-btn-group > .ant-btn:first-child:not(:last-child),
  .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
    border-radius: 5px 0 0 5px;
    height: 36px;
  }
}
</style>
<style lang="less">
.place_setting_popover .ant-popover-inner-content {
  padding: 0 !important;
}
</style>
