<template>
  <div class="ad-main-container">
    <AdHeader :appInfo="appInfo" v-if="appInfo.name" v-on="$listeners" @changePlace="changePlace" />
    <!-- 筛选项 -->
    <div class="search_Box">
      <m-select-more
        class="search_item"
        v-model="query.positionIds"
        :allData="positionList"
        :searchById="false"
        :hasIcon="false"
        :showId="false"
        label="广告样式"
        :showSelectedPart="true"
        :canSelectAll="true"
        @change="getData"
      />
      <m-select-more
        class="search_item"
        v-model="query.adplaceIds"
        :allData="placeList"
        :searchById="true"
        :hasIcon="true"
        :showId="true"
        label="广告位"
        @change="getData"
      />
      <a-select class="search_item" v-model="query.sts" allowClear placeholder="请选择广告位状态" @change="getData">
        <a-select-option v-for="item in auditStsList" :key="item.id">
          {{ item.name }}
        </a-select-option>
      </a-select>
    </div>
    <div class="table">
      <a-spin :spinning="loading">
        <a-table
          class="m_table_cust"
          :pagination="false"
          rowKey="id"
          :columns="columns"
          :data-source="dataList"
          bordered
        >
          <span slot="position" slot-scope="pos">{{ getPosition(pos) }}</span>
          <div class="slot_place" slot="place" slot-scope="name, row">
            <div class="name">{{ row.name || '' }}</div>
            <div class="id">
              ID:{{ row.id
              }}<a-tooltip placement="bottom">
                <template slot="title">
                  <span>复制广告位ID</span>
                </template>
                <CopySvg @click="copyId(row.id)" style="cursor: pointer; margin-left: 5px"/>
              </a-tooltip>
            </div>
          </div>
          <div slot="sts" slot-scope="row">
            <a-switch size="small" default-checked @change="handleChangeSts($event, row)" :checked="row.sts === 'A'" />
          </div>
          <div slot="platNum" slot-scope="row">{{ row.platNum }}个</div>
          <div class="slot_action" slot="actions" slot-scope="row">
            <template v-if="row.creativeTemplateVOS">
              <div class="creativeTemplateVOSBox">
                <span>{{
                  row.creativeTemplateVOS && row.creativeTemplateVOS.length > 0
                    ? row.creativeTemplateVOS[0].name
                    : '暂无数据'
                }}</span>
                <span class="more" @click="lookMore(row.creativeTemplateVOS)">更多详情</span>
              </div>
            </template>
            <template v-else> -- </template>
          </div>
          <a-spin
            slot="sevenDaysRequest"
            slot-scope="sevenDaysRequest, record"
            class="echarts_box"
            :spinning="spinning"
          >
            <div class="chart" :id="'echarts' + record.id"></div>
          </a-spin>
          <div class="slot_action" slot="action" slot-scope="row">
            <a-button size="small" type="cust" @click="toAggregate(row)">聚合管理</a-button>
          </div>
        </a-table>
        <a-pagination
          v-if="dataList.length > 0"
          class="pagination"
          show-size-changer
          :current="query.page"
          :total="total"
          :show-total="(total) => `共 ${total} 条`"
          @change="handleChangePage"
          @showSizeChange="onShowSizeChange"
          :pageSize="query.limit"
        />
      </a-spin>
      <m-empty style="height: 70vh" v-if="dataList.length === 0">
        <template #description> 暂时没有任何数据 </template>
      </m-empty>
    </div>
    <PlaceModal
      v-if="addPlaceVisible"
      :placeInfo="placeInfo"
      :visible="addPlaceVisible"
      @modalCancel="addPlaceVisible = false"
      :isAdd="false"
      @changePlace="changePlace"
    />
    <MoreModal
      v-if="moreVisible"
      :placeInfo="creativeTemplateVOS"
      :visible="moreVisible"
      @modalCancel="moreVisible = false"
    />
  </div>
</template>

<script>
import { getAllMediaList, placeUpdate } from '@/api/media'
import AdHeader from './adHeader'
import { getPositionList } from '@/api/common'
import PlaceModal from '../components/placeModal'
import MoreModal from '../components/more'
import request from '@/utils/request'
import { copy } from '@/utils/string'
import options from './options'
import mixGetList from '@/mixins/getList'
import { mapState } from 'vuex'
import * as echarts from 'echarts'
import CopySvg from '@/assets/icons/copy.svg?inline'
const columns = [
  {
    dataIndex: 'name',
    title: '广告位',
    scopedSlots: { customRender: 'place' },
    align: 'left'
  },
  {
    dataIndex: 'position',
    title: '广告样式',
    scopedSlots: { customRender: 'position' },
    align: 'left'
  },
  {
    dataIndex: '',
    key: 'sts',
    title: '状态',
    scopedSlots: { customRender: 'sts' },
    align: 'left'
  },
  {
    dataIndex: '',
    key: 'platNum',
    title: '第三方广告平台',
    scopedSlots: { customRender: 'platNum' },
    align: 'left'
  },
  {
    dataIndex: '',
    title: '素材规格',
    scopedSlots: { customRender: 'actions' },
    align: 'left'
  },
  {
    dataIndex: 'sevenDaysRequest',
    title: '七日请求数据',
    scopedSlots: { customRender: 'sevenDaysRequest' },
    align: 'left',
    width: 200
  },
  {
    dataIndex: '',
    key: 'action',
    title: '操作',
    scopedSlots: { customRender: 'action' },
    align: 'left',
    width: 200
  }
]
export default {
  components: { AdHeader, PlaceModal, MoreModal, CopySvg },
  mixins: [mixGetList],
  props: {
    appInfo: {
      default: () => ({
        name: ''
      }),
      type: Object
    }
  },
  data () {
    return {
      addPlaceVisible: false,
      placeInfo: {},
      loading: false,
      dataList: [],
      query: {
        page: 1,
        limit: 10,
        appIds: []
      },
      total: 0,
      positionList: [],
      moreVisible: false,
      creativeTemplateVOS: [],
      options,
      spinning: false,
      placeList: [],
      auditStsList: [
        { name: '启用', id: 'A' },
        { name: '暂停', id: 'S' }
      ]
    }
  },
  watch: {
    appInfo: {
      handler (val) {
        this.query.appIds = [val.appId]
        this.getPlaceListByAppIds(this.query.appIds)
        this.getData()
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0]
    }),
    columns () {
      const arr = columns.filter((item) => item.key !== 'sts' && item.key !== 'platNum' && item.key !== 'action')
      return this.role === 'SUPER_ADMIN' ? arr : columns
    }
  },
  created () {
    this.query.appIds = [this.appInfo.appId]
    this.getData()
    this.getgetPositionList()
    // 广告位下拉
    this.getPlaceListByAppIds([this.appInfo.appId])
  },
  mounted () {},
  methods: {
    // 初始化echart
    initChart (data) {
      this.$nextTick((_) => {
        setTimeout((_) => {
          data.forEach((item) => {
            this.options.xAxis.data = item.sevenDaysRequest.map((item) => item.date)
            this.options.series[0].data = item.sevenDaysRequest.map((item) => item.request)
            const mychart = echarts.init(document.getElementById(`${'echarts' + item.id}`), null, { render: 'svg' })
            mychart.setOption(this.options)
            this.spinning = false
          })
        }, 400)
      })
    },
    copyId (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    handleClickUpdatePlace (row) {
      this.placeInfo = row
      this.addPlaceVisible = true
    },
    async getData () {
      this.loading = true
      const resp = await getAllMediaList(this.query)
      if (resp.code === 200) {
        this.dataList = resp.data.items || []
        resp.data.items && this.initChart(resp.data.items)
        this.total = resp.data.total
        this.loading = false
      }
    },
    async getgetPositionList () {
      const resp = await getPositionList()
      this.positionList = resp.data || []
    },
    getPosition (pos) {
      const positionInfo = this.positionList?.find((item) => Number(item.id) === pos)
      if (positionInfo) {
        return positionInfo.name
      } else {
        return ''
      }
    },
    async handleChangeSts (sts, row) {
      this.loading = true
      row.sts = sts ? 'A' : 'S'
      const resp = await placeUpdate(row)
      if (resp.code === 200) {
        this.$message.success('修改成功！')
        this.getData()
      }
    },
    handleDelete (row) {
      request.delete('/place?id=' + row.id).then((resp) => {
        if (resp.code === 200) {
          this.$message.success('删除成功')
          this.getData()
        }
      })
    },
    toAggregate (row) {
      this.$router.push({
        path: '/aggregate/config',
        query: {
          appId: row.appId,
          placeId: row.id
        }
      })
    },
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getData()
    },
    handleChangePage (page) {
      this.query.page = page
      this.getData()
    },
    changePlace () {
      this.getData()
    },
    lookMore (row) {
      this.moreVisible = true
      this.creativeTemplateVOS = row
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/mixins/redefineAnt.less';
.ad-main-container {
  position: relative;
  min-height: 100%;
  background: #fff;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  .search_Box {
    padding: 10px 20px;
    .search_item {
      padding: 0 5px;
      width: 220px;
      display: inline-block;
    }
  }
  .table {
    flex-grow: 1;
    background: #fff;
    padding: 10px;
    .slot_place {
      .name {
        font-size: 13px;
        line-height: 20px;
        color: #333;
      }
      .id {
        font-size: 13px;
        line-height: 20px;
        color: #999;
      }
    }
    .creativeTemplateVOSBox {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .more {
        cursor: pointer;
        color: rgb(51, 102, 255);
      }
    }
  }
  .chart {
    height: 40px;
  }
  .pagination {
    margin: 10px 5px;
  }
}
</style>
