<template>
  <a-modal
    v-model="addPlaceView"
    title="流量素材规则说明"
    type="in"
    @onCancel="handleCancel"
    cancelText="关闭"
    width="900px"
  >
    <div class="addPlaceView">
      <a-table
        :pagination="false"
        :columns="columns"
        :data-source="placeInfo"
        :rowKey="
          (record, index) => {
            return index
          }
        "
        bordered
      >
      </a-table>
    </div>
    <template slot="footer">
      <a-button @click="handleCancel">关闭</a-button>
    </template>
  </a-modal>
</template>
<script>
const columns = [
  {
    dataIndex: 'name',
    title: '名称',
    key: 'name',
    align: 'left'
  },
  {
    dataIndex: 'typeName',
    title: '展示类型',
    key: 'typeName',
    align: 'left'
  },
  {
    dataIndex: 'imageNum',
    title: '图片尺寸',
    key: 'imageNum',
    align: 'left',
    customRender: (text, record, index) => {
      return record.type !== 2 ? record.imageW + '*' + record.imageH : '--'
    }
  },
  {
    dataIndex: 'videoW',
    title: '视频尺寸',
    key: 'videoW',
    align: 'left',
    customRender: (text, record, index) => {
      return record.type === 2 ? record.videoW + '*' + record.videoH : '--'
    }
  },
  {
    dataIndex: 'zijinAccountNo',
    title: '素材格式',
    key: 'zijinAccountNo',
    align: 'left',
    customRender: (text, record, index) => {
      return record.type !== 2 ? 'jpg、png、jpeg' : record.type === 2 ? 'mp4' : '--'
    }
  },
  {
    dataIndex: 'titleLen',
    title: '标题限长(字符)',
    key: 'titleLen',
    align: 'left',
    customRender: (text, record, index) => {
      return record.titleLen || '--'
    }
  },
  {
    dataIndex: 'descLen',
    title: '描述限长(字符)',
    key: 'descLen',
    align: 'leftx',
    customRender: (text, record, index) => {
      return record.descLen || '--'
    }
  }
]
export default {
  data () {
    return {
      columns
    }
  },
  props: {
    placeInfo: {
      type: Array,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  watch: {},
  computed: {
    addPlaceView: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  mounted () {},
  methods: {
    handleCancel () {
      this.$emit('modalCancel')
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep {
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-body {
    background: #eee;
    padding: 20px;
    .addPlaceView {
      background-color: #fff;
      padding: 20px;
      border-radius: 5px;
      min-height: 200px;
      overflow-y: auto;
      .ant-table-tbody > tr > td,
      .ant-table-thead > tr > th {
        border-bottom: none;
        font-size: 12px;
      }
    }
  }
}
</style>
